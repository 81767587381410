<template>
  <div id="map" ref="map" class="map">
    <div ref="total" class="total-data">
      <!-- 面包屑路由 -->
      <div style="margin: 10px 0">
        <el-breadcrumb style="margin-left: 6px" separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item
            ><a href="/#/riskHomeManagement">数据统计</a></el-breadcrumb-item
          >
          <el-breadcrumb-item>数据看板</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <!-- 数据统计 -->
      <div class="card">
        <div class="card-item" v-for="(item, index) in cardList">
          <div class="card-item-left">
            <div>{{ item.title }}</div>
            <div class="card-item-left-data">
              {{ addThousandSeparator(totalData[item.key] || 0) }}
            </div>
            <div
              v-if="item.path"
              @click="goTo(item.path)"
              class="card-item-left-path"
            >
              详情 >
            </div>
          </div>
          <div class="card-item-right">
            <img style="width: 54xp; height: 54px" :src="item.icon" />
          </div>
        </div>
      </div>
      <!-- 地区数据 -->
      <div class="area-title">签约企业分布</div>
      <div class="area-content">
        <div v-for="(map, index) in mapData" class="area-content-item">
          {{ map.name }}（{{ map.data || 0 }}）
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addThousandSeparator } from "@/utils/dealNumber";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import wavePng from "@/assets/images/safeDuty/wave.png";
import { getDataBoard } from "@/api/safeDuty.js";
import { areaTextToCode } from "./js/area.js";
import {
  CSS2DRenderer,
  CSS2DObject,
} from "three/examples/jsm/renderers/CSS2DRenderer.js";
import * as d3 from "d3";
import { Line2 } from "three/examples/jsm/lines/Line2.js";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry.js";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial.js";
import { mapv3 } from "./js/v3.js";
export default {
  mounted() {
    //this.initMap();
  },
  created() {
    this.initData();
  },
  data() {
    return {
      mapData: [],
      totalData: {},
      addThousandSeparator: addThousandSeparator,
      offsetXY: d3.geoMercator(),
      cardList: [
        {
          title: "签约企业总数",
          key: "enterprisesTotalNumber",
          icon: require("@/assets/images/safeDuty/qyqyzs.png"),
          tips: "",
          path: "sdDataBoardEnterprise",
        },
        {
          title: "签约总保费",
          key: "totalPremium",
          icon: require("@/assets/images/safeDuty/qyzbf.png"),
          tips: "",
          path: "sdDataBoardCompany",
        },
        {
          title: "风险减量支出",
          key: "riskDisburse",
          icon: require("@/assets/images/safeDuty/fxjlzc.png"),
          tips: "",
          path: "sdDataBoardService",
        },
        {
          title: "理赔案件支出",
          key: "claimsDisburse",
          icon: require("@/assets/images/safeDuty/lpajzc.png"),
          tips: "",
          path: "",
        },
        {
          title: "满期赔付率",
          key: "maturityLossRatio",
          icon: require("@/assets/images/safeDuty/mqpfl.png"),
          tips: "",
          path: "",
        },
        {
          title: "综合支出率",
          key: "combinedRateExpenditure",
          icon: require("@/assets/images/safeDuty/zhzcl.png"),
          tips: "",
          path: "",
        },
      ],
    };
  },
  methods: {
    initData() {
      getDataBoard().then((res) => {
        this.totalData = res.data;
        Object.keys(res.data.mapData).forEach((key) => {
          this.mapData.push({
            code: areaTextToCode([key])[0],
            name: key,
            data: res.data.mapData[key],
          });
        });
        this.initMap();
      });
    },
    initMap() {
      let width = this.$refs.map.clientWidth;
      let height = this.$refs.map.clientHeight;
      const scene = new THREE.Scene();

      // const axesHelper = new THREE.AxesHelper(5);
      // scene.add(axesHelper);
      const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
      scene.add(ambientLight);
      // const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
      // directionalLight.position.set(0, 10, 5);
      // const directionalLight2 = directionalLight.clone();
      // directionalLight2.position.set(0, 10, -5);
      // const directionalLight3 = directionalLight.clone();
      // directionalLight3.position.set(5, 10, 0);
      // const directionalLight4 = directionalLight.clone();
      // directionalLight4.position.set(-5, 10, 0);
      // scene.add(directionalLight);
      // scene.add(directionalLight2);
      // scene.add(directionalLight3);
      // scene.add(directionalLight4);
      const camera = new THREE.PerspectiveCamera(
        90,
        width / height,
        0.1,
        10000
      );
      //   camera.position.x = 0;
      //   camera.position.y = 80;
      //   camera.position.z = 40;
      camera.position.set(0, 80, 30);

      const labelRenderer = new CSS2DRenderer();
      labelRenderer.domElement.style.position = "absolute";
      labelRenderer.domElement.style.top = "0px";
      labelRenderer.domElement.style.pointerEvents = "none";
      labelRenderer.domElement.style.background;
      labelRenderer.setSize(width, height);
      document.getElementById("map").appendChild(labelRenderer.domElement);

      const renderer = new THREE.WebGLRenderer({ alpha: true });

      renderer.setSize(width, height);
      //document.getElementById("map").appendChild(renderer.domElement);
      this.$refs.map.appendChild(renderer.domElement);
      const controls = new OrbitControls(camera, renderer.domElement);
      controls.update();
      const animate = () => {
        requestAnimationFrame(animate);
        controls.update();
        renderer.render(scene, camera);
        labelRenderer.render(scene, camera);
        //bgRenderer.render(scene, camera);
      };
      animate();
      window.addEventListener("resize", () => {
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
        renderer.setSize(width, height);
        labelRenderer.setSize(width, height);
        //bgRenderer.setSize(window.innerWidth, window.innerHeight);
      });

      // const url =
      //   "https://geo.datav.aliyun.com/areas_v3/bound/100000_full.json";
      // fetch(url)
      //   .then((res) => res.json())
      //   .then((data) => {
      //如果全国地图去除最后的问题数据
      mapv3.features.pop();
      this.mapData.forEach((item) => {
        for (let i = 0; i < mapv3.features.length; i++) {
          let location = mapv3.features[i];
          if (location.properties.name == item.name) {
            location.mapData = item.data || 0;
            break;
          }
        }
      });
      const map = this.createMap(mapv3);
      scene.add(map);
      //
      // const directionalLight = new THREE.DirectionalLight(0xd4e7fd, 1);
      // directionalLight.position.set(0, 10, 5);
      //
      let intersect = null;
      window.addEventListener("pointerdown", (event) => {
        const mouse = new THREE.Vector2();
        mouse.x = (event.offsetX / width) * 2 - 1;
        mouse.y = -(event.offsetY / height) * 2 + 1;
        const raycaster = new THREE.Raycaster();
        raycaster.setFromCamera(mouse, camera);
        const intersects = raycaster
          .intersectObjects(map.children)
          .filter((item) => item.object.type !== "Line");
        if (intersects.length > 0) {
          if (intersects[0].object.type === "Mesh") {
            //console.log(intersects[0].object);
            if (intersect) isAplha(intersect, 1);
            intersect = intersects[0].object.parent;
            isAplha(intersect, 0.4);
          }
          if (intersects[0].object.type === "Sprite") {
            //console.log(intersects[0].object);
          }
        } else {
          if (intersect) isAplha(intersect, 1);
        }
        function isAplha(intersect, opacity) {
          intersect.children.forEach((item) => {
            if (item.type === "Mesh") {
              item.material[0].opacity = opacity;
            }
          });
        }
      });
      // });
    },
    createMap(data) {
      let that = this;
      const map = new THREE.Object3D();
      const center = data.features[0].properties.centroid;
      this.offsetXY.center(center).translate([0, 0]);
      data.features.forEach((feature) => {
        const unit = new THREE.Object3D();
        const { centroid, center, name } = feature.properties;
        const { coordinates, type } = feature.geometry;
        const point = centroid || center || [0, 0];

        const color = new THREE.Color(`#4A8FF2`).getHex();
        const depth = 5;

        const label = this.createLabel(name, point, depth);
        let num = null;
        if (feature.mapData) {
          num = this.createDataNum(name, point, depth, feature.mapData);
        }
        const icon = this.createIcon(center, depth);

        coordinates.forEach((coordinate) => {
          if (type === "MultiPolygon") coordinate.forEach((item) => fn(item));
          if (type === "Polygon") fn(coordinate);

          function fn(coordinate) {
            unit.name = name;
            const mesh = that.createMesh(coordinate, color, depth);
            const line = that.createLine(coordinate, depth);
            unit.add(mesh, ...line);
          }
        });
        if (num) {
          map.add(unit, label, num);
        } else {
          map.add(unit, label);
        }
        this.setCenter(map);
      });
      return map;
    },
    createMesh(data, color, depth) {
      const shape = new THREE.Shape();
      // const textureLoader = new THREE.TextureLoader();
      // let texture = textureLoader.load(wavePng);
      data.forEach((item, idx) => {
        const [x, y] = this.offsetXY(item);

        if (idx === 0) shape.moveTo(x, -y);
        else shape.lineTo(x, -y);
      });
      // const waveMaterial = new THREE.MeshBasicMaterial({
      //   color: "0x22ffcc",
      //   map: texture,
      //   transparent: true,
      //   opacity: 0.1,
      //   side: THREE.DoubleSide,
      //   depthWrite: false,
      // });
      const extrudeSettings = {
        depth: depth,
        bevelEnabled: false,
      };
      const materialSettings = {
        color: "#5297FC",
        //emissive: 0x000000,
        roughness: 0.45,
        metalness: 0.8,
        transparent: true,
        side: THREE.DoubleSide,
      };
      const materialSideSettings = {
        metalness: 1,
        roughness: 1,
        color: "#41a9ff",
      };
      const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
      const materialSide = new THREE.MeshBasicMaterial(materialSideSettings);
      const material = new THREE.MeshBasicMaterial(materialSettings);
      const mesh = new THREE.Mesh(geometry, [material, materialSide]);

      return mesh;
    },
    createLine(data, depth) {
      const points = [];
      const uppoints = [];
      data.forEach((item) => {
        const [x, y] = this.offsetXY(item);
        points.push(new THREE.Vector3(x, -y, 0));
        uppoints.push(x, -y, 0);
      });
      const lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
      const lineGeometryup = new LineGeometry().setPositions(uppoints);
      const uplineMaterial = new LineMaterial({
        color: "#71F6FF",
        linewidth: 3,
      });
      uplineMaterial.resolution.set(window.innerWidth, window.innerHeight);
      const downlineMaterial = new THREE.LineBasicMaterial({
        color: "#71F6FF",
      });

      const upLine = new Line2(lineGeometryup, uplineMaterial);
      upLine.computeLineDistances();
      const downLine = new THREE.Line(lineGeometry, downlineMaterial);
      downLine.position.z = -0.0001;
      upLine.position.z = depth + 0.0001;
      return [upLine, downLine];
    },
    createLabel(name, point, depth) {
      const div = document.createElement("div");
      div.style.color = "#fff";
      div.style.fontSize = "14px";
      div.style.fontWeight = "bold";
      div.style.textShadow = "7px 6px 6px #0C3DD6";
      div.textContent = name;
      const label = new CSS2DObject(div);
      label.scale.set(0.01, 0.01, 0.01);
      const [x, y] = this.offsetXY(point);
      label.position.set(x, -y, depth);
      return label;
    },
    createDataNum(name, point, depth, data) {
      let maindiv = document.createElement("div");
      const div1 = document.createElement("div");
      const div2 = document.createElement("div");
      const div3 = document.createElement("div");
      const div4 = document.createElement("div");
      const div5 = document.createElement("div");
      //下面圆圈
      const div6 = document.createElement("div");
      const div7 = document.createElement("div");
      const divc1 = document.createElement("div");
      const divc2 = document.createElement("div");
      const divc3 = document.createElement("div");
      div7.appendChild(divc3);
      divc3.appendChild(divc2);
      divc2.appendChild(divc1);
      divc1.appendChild(div6);
      /////
      maindiv.appendChild(div3);
      maindiv.appendChild(div5);
      maindiv.appendChild(div7);
      div5.appendChild(div4);
      div2.appendChild(div1);
      div3.appendChild(div2);

      maindiv.style.display = "flex";
      maindiv.style.flex = "1";
      maindiv.style.justifyContent = "center";
      maindiv.style.width = "fit-content";
      //三圈加数字
      div1.textContent = data;
      div1.style.backgroundColor = "#FF8A54";
      div1.style.color = "#FFFFFF";
      div1.style.fontSize = "14px";
      div1.style.fontWeight = "bold";
      div1.style.display = "flex";
      div1.style.alignItems = "center";
      div1.style.justifyContent = "center";
      div1.style.height = "30px";
      div1.style.minWidth = "30px";
      div1.style.borderRadius = "25px";
      div1.style.boxSizing = "border-box";
      div2.style.border = "6px solid rgba(255, 255, 255, 0.38)";
      div2.style.borderRadius = "26px";
      div3.style.border = "6px solid rgba(255, 255, 255, 0.15)";
      div3.style.borderRadius = "27px";
      //圈圈下面的长条
      div4.style.width = "4px";
      div4.style.height = "44px";
      div4.style.background =
        "linear-gradient( 180deg, rgba(255,170,131,0) 0%, #FFBD9E 100%)";
      div5.style.display = "flex";
      div5.style.justifyContent = "center";
      //下面圆圈
      div6.style.width = "25px";
      div6.style.height = "9px";
      div6.style.background =
        "radial-gradient( 0% 0% at 24% 50%, #FFFFFF 0%, #FF8A54 100%)";
      div6.style.boxShadow = "0px 3px 6px 1px rgba(154,64,23,0.52)";
      div6.style.borderRadius = "60%";
      div7.style.display = "flex";
      div7.style.justifyContent = "center";
      div7.style.position = "absolute";
      div7.style.top = "80px";
      div7.style.left = "-22px";
      divc3.style.display = "flex";
      divc3.style.justifyContent = "center";
      divc3.style.alignItems = "center";
      divc3.style.width = "98px";
      divc3.style.height = "39px";
      // divc3.style.border =
      //   "2px solid linear-gradient( 122deg, rgba(255,255,255,0) 0%, rgba(255,138,84,0.31) 100%)";
      divc3.style.background =
        "linear-gradient( 122deg, rgba(255,255,255,0) 0%, rgba(255,138,84,0.31) 100%)";
      divc3.style.borderRadius = "60%";
      divc2.style.display = "flex";
      divc2.style.justifyContent = "center";
      divc2.style.alignItems = "center";
      divc2.style.width = "79px";
      divc2.style.height = "32px";
      // divc2.style.border =
      //   "2px solid linear-gradient( 122deg, rgba(255,255,255,0) 0%, rgba(255,138,84,0.31) 100%)";
      divc2.style.background =
        "linear-gradient( 122deg, rgba(255,255,255,0) 0%, rgba(255,138,84,0.31) 100%)";
      divc2.style.borderRadius = "60%";
      divc1.style.display = "flex";
      divc1.style.justifyContent = "center";
      divc1.style.alignItems = "center";
      divc1.style.width = "42px";
      divc1.style.height = "20px";
      // divc1.style.border =
      //   "2px solid linear-gradient( 122deg, rgba(255,255,255,0) 0%, rgba(255,138,84,0.31) 100%)";
      divc1.style.background =
        "linear-gradient( 122deg, rgba(255,255,255,0) 0%, rgba(255,138,84,0.31) 100%)";
      divc1.style.borderRadius = "60%";
      ///////////////
      let tdiv = `
      <div style="
      display: flex;
      flex-direction: column;
      align-items: center;
      width: fit-content;
      ">
        <div style="
        border-radius: 27px;
        border: 6px solid rgba(255, 255, 255, 0.15);
        display: flex;
        ">
          <div style="border-radius: 26px; border: 6px solid rgba(255, 255, 255, 0.38)">
            <div style="
            background-color: #ff8a54;
            forn-size: 14px;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 30px;
            height: 30px;
            border-radius: 25px;
            color: #ffffff;
            ">
              ${data || 0}
            </div>
          </div>
        </div>
        <div style="display: flex; flex-direction: column;justify-content: center; align-items: center;position: relative;">
          <div style="
          width: 4px;
          height: 44px;
          background: linear-gradient(
          180deg,
          rgba(255, 170, 131, 0) 0%,
          #ffbd9e 100%
          );
          ">
          </div>
            <svg style="border-radius: 100%;position: absolute;bottom: -25px;" id="svgDom" width="98" height="38">
              <ellipse   cx="49" cy="16" rx="12" ry="4" style="fill:rgba(255,138,84,0.8)">
              </ellipse>
              <ellipse  cx="49" cy="16" rx="0" ry="0" stroke="#ff8a54" stroke-width="5" fill-opacity="0">
                <animate attributeName="rx" id="ani1" begin="0" from="0" to="98" dur="1.5s"
                repeatCount="indefinite">
                </animate>
                <animate attributeName="ry" id="ani1" begin="0" from="0" to="38" dur="1.5s"
                repeatCount="indefinite">
                </animate>
                <animate attributeName="opacity" begin="0" from="1" to="0" dur="1.5s"
                repeatCount="indefinite">
                </animate>
              </ellipse>
              <ellipse  cx="49" cy="16" rx="0" ry="0" stroke="#ff8a54" stroke-width="5" fill-opacity="0">
                <animate attributeName="r" begin="ani1.begin + 0.5s" from="0" to="98"
                dur="1.5s" repeatCount="indefinite">
                </animate>
                <animate attributeName="r" begin="ani1.begin + 0.5s" from="0" to="38"
                dur="1.5s" repeatCount="indefinite">
                </animate>
                <animate attributeName="opacity" begin="ani1.begin + 0.5s" from="1" to="0"
                dur="1.5s" repeatCount="indefinite">
                </animate>
              </ellipse>
              <ellipse  cx="49" cy="16" rx="0" ry="0" stroke="#ff8a54" stroke-width="5" fill-opacity="0">
                <animate attributeName="r" begin="ani1.begin + 1s" from="0" to="98" dur="1.5s"
                repeatCount="indefinite">
                </animate>
                <animate attributeName="r" begin="ani1.begin + 1s" from="0" to="38" dur="1.5s"
                repeatCount="indefinite">
                </animate>
                <animate attributeName="opacity" begin="ani1.begin + 1s" from="1" to="0"
                dur="1.5s" repeatCount="indefinite">
                </animate>
              </ellipse>
            </svg>
          </div>
        </div>
      `;
      //需要波浪动画放开下面的注释代码
      //maindiv = this.createHtml(tdiv);
      const num = new CSS2DObject(maindiv);
      //num.scale.set(0.01, 0.01, 0.01);
      // depth+5将标点图标 Z向上移动
      const [x, y] = this.offsetXY(point);
      num.position.set(x, -y, depth + 4);
      return num;
    },
    createIcon(point, depth) {
      // const url =
      //   "https://yili-platform-v2.oss-cn-shenzhen.aliyuncs.com/common/basic/miniapp/ui/lpan-20231101.png";
      const map = new THREE.TextureLoader().load(wavePng);
      const material = new THREE.SpriteMaterial({
        color: "0x22ffcc",
        map: map,
        transparent: true,
        //opacity: 0.1,
        side: THREE.DoubleSide,
        depthWrite: false,
      });
      const sprite = new THREE.Sprite(material);
      const [x, y] = this.offsetXY(point);
      sprite.scale.set(10, 10, 10);
      sprite.position.set(x, -y, depth + 4);
      sprite.renderOrder = 1;
      return sprite;
    },
    setCenter(map) {
      map.rotation.x = -Math.PI / 2;
      const box = new THREE.Box3().setFromObject(map);
      const center = box.getCenter(new THREE.Vector3());

      const offset = [0, 0];
      // map.position.x = map.position.x - center.x - offset[0];
      // map.position.z = map.position.z - center.z - offset[1];
      map.position.x = 0;
      map.position.z = -10;
    },
    createHtml(str) {
      if (!str) return;
      let parser = new DOMParser();
      let html = parser.parseFromString(str, "text/html");
      return html.body.firstChild;
    },
    goTo(path) {
      this.$router.push({
        name: path,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-main {
  padding: unset !important;
}
.map {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/safeDuty/sdbg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
}
.total-data {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  background: transparent;
  padding: 10px;
  .card {
    display: flex;
    overflow: auto;
    justify-content: space-between;
    z-index: 99;
    &-item {
      width: 270px;
      height: 125px;
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 10px 10px 10px 10px;
      opacity: 0.8;
      padding: 16px;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      display: flex;
      justify-content: space-between;
      &-left {
        display: flex;
        flex-direction: column;
        &-data {
          font-weight: bold;
          font-size: 24px;
          margin-top: 10px;
        }
        &-path {
          margin-top: 8px;
          color: #377dff;
          cursor: pointer;
        }
      }
      &-right {
        display: flex;
        align-items: center;
      }
    }
  }
  .area {
    // display: inline-flex;
    // justify-content: space-between;
    // margin-top: 24px;
    &-title {
      width: 145px;
      height: 44px;
      background: linear-gradient(180deg, #c2deff 0%, #ffffff 100%);
      box-shadow: 0px 3px 10px 1px rgba(1, 68, 135, 0.16);
      border-radius: 10px 10px 34px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 18px;
      color: #333333;
      font-family: YouSheBiaoTiHei, YouSheBiaoTiHei;
      position: absolute;
      top: 180px;
      left: 10px;
      z-index: 99;
    }
    &-content {
      font-weight: 400;
      font-size: 16px;
      color: #333333;
      text-align: left;
      width: 270px;
      max-height: 629px;
      background: rgba(255, 255, 255, 0.8);
      border-radius: 10px 10px 10px 10px;
      overflow: auto;
      padding: 0 24px;
      position: absolute;
      top: 180px;
      right: 10px;
      z-index: 99;
      &-item {
        border-bottom: 1px solid #dee3e6;
        padding: 24px 0;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
</style>
<style>
/* .SubContent {
  padding: unset !important;
} */
</style>
